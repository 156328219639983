<template>
  <div class="blog-post-card">
    <div class="max-w-sm rounded overflow-hidden shadow-2xl">
      <img
        v-if="details.img"
        class="w-full"
        :src="require(`@/assets/Resources/Blog/${details.img}`)"
      />
      <div class="px-6 py-4 text-left">
        <div class="title font-bold text-xl mb-2">
          <router-link class="link" :to="details.link">
            {{ details.title }}
          </router-link>
        </div>
        <p>
          By <span class="font-bold">{{ details.author }}</span> |
          {{ details.date }} |
          <span class="font-bold">{{ details.category }}</span>
        </p>
        <p class="text-gray-700 text-base pt-4">
          {{ details.preview }}
        </p>
        <p class="pt-4 font-bold">
          <router-link class="link" :to="details.link">
            Read More >
          </router-link>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BlogPostCard",
  props: {
    details: Object
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.blog-post-card {
  text-align: left;

  .title {
    font-size: 18px;
  }

  p {
    font-size: 12px;
  }

  .link {
    color: #aa222c;
  }
}
</style>
